import React, { useState, useEffect } from "react";
import { api_options } from "../../helpers/API";
import HighStockWrapper from "./HighStockWrapper";
import { LoadingPage } from "../../helpers/SimpleComponents";
import "../../../src/screens/ResearchCenter/style.css"

const API_CHART_TYPE = 1;

const colorGradients = [
    "#2e3e50", // black
    "#8f44ad", // purple
    "#2b80b9", // blue
    "#11a186", // green
    "#f1c40f", // yellow
    "#e67d23", // dark yellow
    "#e84d3d", // orange
    "#c03a2b", // red
];

/*
    - makes API call to 'Futures' DB / 'Options' Table
    - returns timeseries of different ATM Options strikes and implied volatility for each contract
    - plots line chart with color-coded contracts of IV, ATM strike, and underlying close on each day
*/
const OptionsIvHistories = (props) => {

    const [state, setState] = useState({});
    const [error, setError] = useState(false);

    // get new data whenever props.root changes
    useEffect(() => {
        getData(props.root, props.interval);
    }, [props.root, props.interval]);


    const convertJSON = (data) => {
        let res = new Map();
        for (let i = 0; i < data.length; i++) {
            const cur = data[i];
            if (res.has(cur["chain"])) {
                res.get(cur["chain"]).push(cur);
            } else { // new chain, add to map with new list
                res.set(cur["chain"], [cur]);
            }
        }
        return res;
    };


    // setup array in format for HighStocks series: [{x: timestamp, y: column ,...,} ,...,]
    const configSeries = (column) => {
        const chainsAr = state.chains;
        let gradientIndex = 0;
        let series = [];
        for (let i = 0; i < chainsAr.length; i++) {
            let curChain = state.seriesMap.get(chainsAr[i]);
            if (curChain.length > 1) {
                series.push({
                    name: `${chainsAr[i]}`,
                    data: curChain.map(row => {
                        return {x: row["timestamp"], y: row[column], 
                            atm_strike: row["atm_strike"], under_close: row["under_close"], serial: row["serial"], new_crop: row["new_crop"]}
                    }),
                    color: colorGradients[gradientIndex++],
                    lineWidth: curChain[0]["serial"] === 0 ? 2.5 : 1, // make non-serial chains thicker
                    turboThreshold: 0
                });
                if (gradientIndex >= colorGradients.length) gradientIndex = 0;
            }
        }
        return series;
    };


    // colored line charts for each contract
    const configChart = () => {
        return {
            title: {
                text: `${props.title} : IV History`
            },
            legend: {
                enabled: true
            },
            navigator: {
                enabled: false
            },
            scrollbar: {
                enabled: true
            },
            rangeSelector: {
                buttons: [
                    {
                        type: "week",
                        count: 1,
                        text: "1w",
                    }, {
                        type: "week",
                        count: 2,
                        text: "2w"
                    }, {
                        type: "week",
                        count: 4,
                        text: "4w"
                    }, {
                        type: "all",
                        text: "All"
                    }
                ],
                inputDateFormat: "%Y-%m-%d",
                setInputEnabled: true, // known highcharts bug with text overlap
            },
            xAxis: {
                type: "datetime",
                labels: {
                    format: '{value:%m/%e}'
                }
            },
            yAxis: {
                title: {
                    text: "ATM IV",
                    offset: 25
                },
                labels: {
                    align: "right",
                    x: 22,
                    formatter: function() {
                        return (this.value).toFixed(0)+"%";
                    }
                },
                //tickInterval: 1
            },
            series: configSeries("atm_iv"),
            tooltip: {
                xDateFormat: "%a, %b %e, '%y", // Thu, Dec 16, 21
                pointFormatter: function() {
                    let point = this;
                    let format = `
                            <span style="color: ${point.series.color}">${point.series.name}</span><br/>
                            ATM IV: <b>${(point.y).toFixed(2)}</b><br/>
                            strike: ${point.atm_strike}<br/>
                            close: ${(point.under_close).toFixed(2)}<br/>`;
                    if (point.serial)
                        format += '<b>serial</b><br/>';
                    if (point.new_crop)
                        format += '<b>new crop</b><br/>';
                    return format;
                },
                split: false,
                shared: false
            },
            credits: {
                enabled: false
            }
        }
    };


    const getData = (root, interval) => {
        // console.log(`getting data for ${root}`)
        api_options({"root" : root, "chart" : API_CHART_TYPE, "interval" : interval})
            .then((res) => {
                const seriesMap = convertJSON(res.data.data);
                setState({data: res.data.data, chains: Array.from(seriesMap.keys()), seriesMap: seriesMap, curRoot: root});
            }).catch((e) => {
                setError(true);
                console.error(e);
            });
    };


    if (typeof props.root === "undefined" || error) {
        return <h3 style={{color: "red"}}>{`Error: ( ${props.title} : ${props.root} ) - unrecognized.`}</h3>
    } else if (typeof state.seriesMap !== "undefined" && state.curRoot === props.root) {
        const ChartEngine = () => {
            return (<HighStockWrapper config={configChart()}/>);
        };
        return (<div> {ChartEngine()} </div>);
    } else {
        return (<div> {LoadingPage()} </div>);
    }
}

export default OptionsIvHistories;
