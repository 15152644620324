import React, { useState, useEffect } from "react";
import { api_options } from "../../helpers/API";
import HighStockWrapper from "./HighStockWrapper";
import {LoadingPage} from "../../helpers/SimpleComponents";
import "../../../src/screens/ResearchCenter/style.css"

const MAX_CHAINS = 10;
const API_CHART_TYPE = 2;

/*
    - makes API call to 'Futures' DB / 'Options' Table
    - returns map of open/high/low/close implied volatility data for each contract over specified time interval
    - plots candlestick point for each contract
*/
const OptionsIvSnapshot = (props) => {

    const [state, setState] = useState({});
    const [error, setError] = useState(false);

    // get new data whenever props.root changes
    useEffect(() => {
        getData(props.root, props.interval);
    }, [props.root, props.interval]);


    const convertJSON = (data) => {
        let res = new Map();
        data.forEach((ele) => res.set(ele['chain'], ele));
        return res;
    };

    /*
    data: [{
        x: 1,
        open: 9,
        high: 2,
        low: 4,
        close: 6,
        name: "Point2",
        color: "#00FF00"
    }, ... {} ]
    */
    const configSeries2 = () => {
        // [curChain["timestamp"], curChain["iv_open"], curChain["iv_high"], curChain["iv_low"], curChain["iv_close"]]
        const chainsAr = state.chains;
        let dataAr = [];
        for (let i = 0; i < Math.min(chainsAr.length, MAX_CHAINS); i++) { // stop after MAX_CHAINS limit
            let cur = state.seriesMap.get(chainsAr[i]);
            dataAr.push({
                x: i,
                open: cur["iv_open"], 
                high: cur["iv_high"], 
                low: cur["iv_low"], 
                close: cur["iv_close"],
                name: cur["chain"],
                color: cur["iv_close"] < cur["iv_open"] ? "#ce5042" : "#39ace7"
            });
        }
        return dataAr;
    };


    // candlestick chart highlighting open/high/low/close for each contract's history
    const chartConfig2 = () => {
        // console.log(state.chains)
        return {
            title: {
                text: `${props.title} : IV Snapshot`
            },
            legend: {
                enabled: false
            },
            navigator: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            xAxis: {
                labels: {
                    formatter() {
                        let chain = state.chains[this.value];
                        return (chain.charAt(chain.length-1)+chain.charAt(chain.length-3)+chain.charAt(chain.length-2));
                    },
                    rotation: 0
                },
                tickInterval: 1,
                
            },
            rangeSelector: {
                enabled: false
            },
            yAxis: {
                title: {
                    text: "ATM IV",
                    offset: 25
                },
                labels: {
                    align: "right",
                    x: 22, // offset
                    formatter: function() {
                        return (this.value).toFixed(0)+"%";
                    }
                },
            },
            plotOptions: { 
                series: { animation: false },
                
            },
            series: [{
                type: "candlestick",
                data: configSeries2(),
                turboThreshold: 0
            }],
            tooltip: {
                pointFormatter: function() {
                    let point = this;
                    let format = `
                            open: ${(point.open).toFixed(2)}<br/>
                            high: ${(point.high).toFixed(2)}<br/>
                            low: ${(point.low).toFixed(2)}<br/>
                            close: ${(point.close).toFixed(2)}<br/>`
                    return format;
                },
                split: false
            },
            credits: {
                enabled: false
            }
        }
    };


    const getData = (root, interval) => {
        // console.log(`getting data for ${root}`)
        api_options({"root" : root, "chart" : API_CHART_TYPE, "interval" : interval})
            .then((res) => {
                const seriesMap = convertJSON(res.data.data);
                setState({data: res.data.data, chains: Array.from(seriesMap.keys()), seriesMap: seriesMap, curRoot: root});
            }).catch((e) => {
                setError(true);
                console.log(e);
            });
    }


    if (typeof props.root === "undefined" || error) {
        return <h3 style={{color: "red"}}>{`Error: ( ${props.title} : ${props.root} ) - unrecognized.`}</h3>
    } else if (typeof state.seriesMap !== "undefined" && state.curRoot === props.root) {
        const ChartEngine = () => {
            console.log("?")
            return (<HighStockWrapper config={chartConfig2()}/>);
        };
        return (<div> {ChartEngine()} </div>);
    } else {
        return (<div> {LoadingPage()} </div>);
    }
}

export default OptionsIvSnapshot;
