import React, { useState, useEffect } from 'react';
import styles from '../../css/TopStrikes.module.css';
import { LoadingPage } from "../../helpers/SimpleComponents";
import { by_exchange } from "../../universalSymbolMap";
import ExpectedRisk from '../../screens/ResearchCenter/ExpectedRisk';
import zscoreStyles from "../../css/DashboardQuotes.module.css";

document.title = "Symbol List Risk";

var wsUrl = ( window.location.protocol === 'https:'
    ? "wss://static.cbot.comatl.com:49534/"
    : "ws://static.cbot.comatl.com:49533/"
);

const productTypes = {
    0: 'Future',
    1: 'Option',
    2: 'Spread',
}


/*
    - Interactive table that initially shows front-months across all assets in sorted desc order of Z-Score price movements
    - Clicking a row/asset will trigger another WS message to fetch future/spread/option contracts within that asset
    - Clicking again on a specific future/spread/option will render the ExpectedRisk chart with history (and prices)
    - Click 'reload' to return to the original page that fetches across all asset front-months
*/
const SymbolsRiskTable = (props) => {
    const [data, setData] = useState(null);
    const [asset, setAsset] = useState(null); // used in getData() to fetch asset/root's products 
    const [chartSymbol, setChartSymbol] = useState(null); // used in ExpectedRisk chart

    // handle WebSocket open/onMessage/close and update 'data' state
    // grabs all front-months by default unless asset is specified, then grabs futures/spreads/options for asset
    const getData = (specificAsset=null) => {
        const socket = new WebSocket(wsUrl);

        setAsset(specificAsset);
        setData(null);
        setChartSymbol(null);

        socket.onopen = () => {
            if (props.symbols) {
                // console.log("sending msg")
                socket.send(JSON.stringify({
                    "type": "start", 
                    "source": specificAsset ? "asset_risk" : "symbol_list_risk", 
                    "authorization": "Bearer ".concat(localStorage.getItem("_token")), 
                    "args": specificAsset ? {"asset": specificAsset} : {"symbols":props.symbols}
                }));
            }

            socket.onmessage = (event) => {
                const json = JSON.parse(event.data);
                try {
                    // console.log(json);
                    if (json['type'] && json['type'] === 'data') {
                        // default sort by the score
                        const sortedData = [...json["data"]].sort(
                            (a, b) => Math.abs(b.score || 0) - Math.abs(a.score || 0)
                        ).map((ele) => {
                            // create new custom attributes
                            return {
                                ...ele,
                                percentChange: (ele.change / ele.prev),
                                riskPercent: (ele.risk / ele.prev),
                            };
                        });
                        setData(sortedData);
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        }

        return () => {
            socket.close();
        };
    }

    useEffect(() => {
        getData();
    }, [props.symbols]);

    let columns = [
        // { label: "", accessor: "", sortable: false },
        { label: "Symbol", accessor: "symbol", sortable: true },
        // show product type when asset specified, list root/asset when showing all roots
        (asset) ? {label: "Type", accessor: "type", sortable: true} : { label: "Root", accessor: "asset", sortable: true },
        { label: "Session", accessor: "session", sortable: true },
        { label: "Time", accessor: "time", sortable: true },
        { label: "Last Price", accessor: "px", sortable: true },
        { label: "Price Change", accessor: "change", sortable: true },
        { label: "% Change", accessor: "percentChange", sortable: true },
        { label: "Std Dev", accessor: "stddev", sortable: true },
        { label: "Risk", accessor: "risk", sortable: true },
        { label: "Risk %", accessor: "riskPercent", sortable: true },
        { label: "Score", accessor: "score", sortable: true },
    ];

    const abs_sort_fields = ['percentChange', 'score'];

    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {
            const sorted = [...data].sort((a, b) => {
                if (a[sortField] === null && b[sortField] === null) return 0;
                if (a[sortField] === null) return 1;
                if (b[sortField] === null) return -1;
                return (
                    (
                        (typeof a[sortField] === 'string' && typeof b[sortField] === 'string') ?
                            (a[sortField].localeCompare(b[sortField])) // localCompare() String
                        : (abs_sort_fields.includes(sortField) ? // check nums for Absolute Value sorting
                            (Math.abs(a[sortField]) - Math.abs(b[sortField])) : (a[sortField] - b[sortField])) 
                    ) * (sortOrder === "asc" ? 1 : -1)
                );
            });
            setData(sorted);
        }
    };

    // Clicking the row will either fetch data for all symbols of asset, 
    //  or when a specific symbol row is clicked, it will trigger fetching ExpectedRisk chart
    const handleRowClick = (rowData) => {
        if (asset) {
            setChartSymbol(rowData.symbol);
        } else {
            getData(rowData.asset);
        }
    }

    if (data === null) {
        return (<>{ LoadingPage() }</>);
    } else {
        const dataError = (!data || data.length === 0);
        return (
            <>
                <button style={{marginLeft: '20px'}} onClick={() => { getData() }}> reset </button>
                {!chartSymbol && // show table for assets/symbol if no speficic symbol clicked
                <>
                    { asset && 
                        <h2 style={{textAlign: 'center', margin: '-10px'}}>
                            {`${asset} Futures, Spreads, & Options`}
                        </h2>
                    }
                    <table className={styles.table}>
                        {dataError &&
                        <p style={{color: 'red'}}> DATA ERROR </p>
                        }
                        <SymbolsRiskHead columns={columns} handleSorting={handleSorting}/>
                        <SymbolsRiskBody columns={columns} tableData={data} childGetData={getData} handleRowClick={handleRowClick}/>
                    </table>
                </>
                }
                {chartSymbol && // If specific symbol cliked, render its ExpectedRisk chart to see risk/price history
                    <ExpectedRisk symbol={chartSymbol}/>
                }
            </>
        );
    }
};


const SymbolsRiskHead = ({ columns, handleSorting }) => {
    const [sortField, setSortField] = useState("");
    const [order, setOrder] = useState("desc");

    const handleSortingChange = (accessor) => {
        const sortOrder = (accessor === sortField && order === "desc") ? "asc" : "desc";
        setSortField(accessor);
        setOrder(sortOrder);
        handleSorting(accessor, sortOrder);
    }

    return (
        <thead>
            <tr>
                {columns.map(({ label, accessor, sortable }) => {
                    const cl = sortable
                        ? sortField === accessor && order === "asc"
                            ? "up"
                            : sortField === accessor && order === "desc"
                            ? "down"
                            : "default"
                        : "";
                    return (
                        <th 
                        style={{textAlign: 'center'}} 
                        key={accessor} 
                        onClick={sortable ? () => handleSortingChange(accessor) : null} 
                        className={styles[cl]}
                        >
                            {label}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};


const SymbolsRiskBody = ({ tableData, columns, handleRowClick }) => {
    const truncateCols = ['stddev', 'risk', 'score', 'percentChage'];
    const percentCols = ['percentChange', 'riskPercent'];
    return (
        <tbody>
            {tableData.map((data) => {
                return (
                    <tr key={data.symbol}  onClick={() => { handleRowClick(data) }} >
                        {columns.map(({ accessor }) => {
                            let curVal = data[accessor];
                            let curClassName = "";

                            // for asset column, grab the title description from USM if available
                            const usmTitle = accessor === 'asset' ? by_exchange(data.asset) : null;
                            const title = usmTitle && usmTitle.title ? usmTitle.title : "";

                            if (accessor === 'score') curClassName = getCellStyle(data['score']);

                            if (curVal !== null && truncateCols.includes(accessor)) curVal = curVal.toFixed(3);
                            if (curVal !== null && percentCols.includes(accessor)) curVal = (curVal * 100).toFixed(1) + '%';
                            if (accessor === 'type') curVal = productTypes[data['type']];

                            const tData = data[accessor] !== undefined ? curVal : "—";
                            return (
                                <td 
                                key={accessor} 
                                title={title} 
                                className={zscoreStyles[curClassName]}>
                                    {tData}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};

// return different styles of color/bold dependending on scale of +/- z-score move
const getCellStyle = (val) => {
    if (val <= -2) {
        return 'z3n';
    } else if (val <= -1) {
        return 'z2n';
    } else if (val <= 0) {
        return 'z1n';
    } else if (val <= 1) {
        return 'z1p';
    } else if (val <= 2) {
        return 'z2p';
    } else {
        return 'z3p';
    }
};

export default SymbolsRiskTable;