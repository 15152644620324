import React from 'react';
import SymbolsRiskTable from '../../components/table/SymbolsRiskTable';
import { valid_common, by_common } from "../../universalSymbolMap";

const activeSymbols = valid_common().map((ele) => by_common(ele)).filter(
    (ele) => ele.active && ele.active.symbol
).map((ele) => ele.active.symbol);


const SymbolListRisk = () => {

    return (
        <>
            <div style={{textAlign: 'center'}}>
                <h1> Price Changes & Standard Deviations </h1>
                <p style={{margin: '-20px'}}> {"(sortable, click to see more)"} </p>
            </div>
            <SymbolsRiskTable symbols={activeSymbols}/>
        </>
    )
}

export default SymbolListRisk;