//  Automatically generated file, do not edit. Contents will be overwritten.

export const _exchange_symbol_lookup = {
  "6A": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "6A_2024Z",
      "session": "2024-11-11",
      "symbol": "6AZ4"
    },
    "bloomberg": [
      "AD",
      "Curncy"
    ],
    "bloomberg_root": "AD",
    "common": "AD",
    "exchange": "6A",
    "rolled": {
      "D": "AD1 A:00_0_D Curncy",
      "N": "AD1 A:00_0_N Curncy",
      "R": "AD1 A:00_0_R Curncy"
    },
    "sector": "Currency",
    "title": "Australian Dollar",
    "v9": true
  },
  "6B": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "6B_2024Z",
      "session": "2024-11-11",
      "symbol": "6BZ4"
    },
    "bloomberg": [
      "BP",
      "Curncy"
    ],
    "bloomberg_root": "BP",
    "common": "BP",
    "exchange": "6B",
    "rolled": {
      "D": "BP1 A:00_0_D Curncy",
      "N": "BP1 A:00_0_N Curncy",
      "R": "BP1 A:00_0_R Curncy"
    },
    "sector": "Currency",
    "title": "British Pound",
    "v9": true
  },
  "6C": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "6C_2024Z",
      "session": "2024-11-11",
      "symbol": "6CZ4"
    },
    "bloomberg": [
      "CD",
      "Curncy"
    ],
    "bloomberg_root": "CD",
    "common": "CD",
    "exchange": "6C",
    "rolled": {
      "D": "CD1 A:00_0_D Curncy",
      "N": "CD1 A:00_0_N Curncy",
      "R": "CD1 A:00_0_R Curncy"
    },
    "sector": "Currency",
    "title": "Canadian Dollar",
    "v9": true
  },
  "6E": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "6E_2024Z",
      "session": "2024-11-11",
      "symbol": "6EZ4"
    },
    "bloomberg": [
      "EC",
      "Curncy"
    ],
    "bloomberg_root": "EC",
    "common": "EC",
    "exchange": "6E",
    "rolled": {
      "D": "EC1 A:00_0_D Curncy",
      "N": "EC1 A:00_0_N Curncy",
      "R": "EC1 A:00_0_R Curncy"
    },
    "sector": "Currency",
    "title": "Euro FX",
    "v9": true
  },
  "6J": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "6J_2024Z",
      "session": "2024-11-11",
      "symbol": "6JZ4"
    },
    "bloomberg": [
      "JY",
      "Curncy"
    ],
    "bloomberg_root": "JY",
    "common": "JY",
    "exchange": "6J",
    "rolled": {
      "D": "JY1 A:00_0_D Curncy",
      "N": "JY1 A:00_0_N Curncy",
      "R": "JY1 A:00_0_R Curncy"
    },
    "sector": "Currency",
    "title": "Japanese Yen",
    "v9": true
  },
  "6L": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "6L_2024Z",
      "session": "2024-11-11",
      "symbol": "6LZ4"
    },
    "bloomberg": [
      "BR",
      "Curncy"
    ],
    "bloomberg_root": "BR",
    "common": "BR",
    "exchange": "6L",
    "rolled": {
      "D": "BR1 A:00_0_D Curncy",
      "N": "BR1 A:00_0_N Curncy",
      "R": "BR1 A:00_0_R Curncy"
    },
    "sector": "Currency",
    "title": "Brazilian Real",
    "v9": true
  },
  "6M": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "6M_2024Z",
      "session": "2024-11-11",
      "symbol": "6MZ4"
    },
    "bloomberg": [
      "PE",
      "Curncy"
    ],
    "bloomberg_root": "PE",
    "common": "PE",
    "exchange": "6M",
    "rolled": {
      "D": "PE1 A:00_0_D Curncy",
      "N": "PE1 A:00_0_N Curncy",
      "R": "PE1 A:00_0_R Curncy"
    },
    "sector": "Currency",
    "title": "Mexican Peso",
    "v9": true
  },
  "6N": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "6N_2024Z",
      "session": "2024-11-11",
      "symbol": "6NZ4"
    },
    "bloomberg": [
      "NV",
      "Curncy"
    ],
    "bloomberg_root": "NV",
    "common": "NV",
    "exchange": "6N",
    "rolled": {
      "D": "NV1 A:00_0_D Curncy",
      "N": "NV1 A:00_0_N Curncy",
      "R": "NV1 A:00_0_R Curncy"
    },
    "sector": "Misc",
    "title": "New Zealand Dollar",
    "v9": true
  },
  "6S": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "6S_2024Z",
      "session": "2024-11-11",
      "symbol": "6SZ4"
    },
    "bloomberg": [
      "SF",
      "Curncy"
    ],
    "bloomberg_root": "SF",
    "common": "SF",
    "exchange": "6S",
    "rolled": {
      "D": "SF1 A:00_0_D Curncy",
      "N": "SF1 A:00_0_N Curncy",
      "R": "SF1 A:00_0_R Curncy"
    },
    "sector": "Currency",
    "title": "Swiss Franc",
    "v9": true
  },
  "BRN": {
    "bloomberg": [
      "CO",
      "Comdty"
    ],
    "bloomberg_root": "CO",
    "common": "CO",
    "delta": {
      "root": "B"
    },
    "exchange": "BRN",
    "rolled": {
      "D": "CO1 R:02_0_D Comdty",
      "N": "CO1 R:02_0_N Comdty",
      "R": "CO1 R:02_0_R Comdty"
    },
    "sector": "Energy",
    "title": "Brent Crude"
  },
  "BTC": {
    "active": {
      "month_yr": "NOV 24",
      "rel": "BTC_2024X",
      "session": "2024-11-11",
      "symbol": "BTCX4"
    },
    "bloomberg": [
      "BTC",
      "Curncy"
    ],
    "bloomberg_root": "BTC",
    "common": "BTC",
    "exchange": "BTC",
    "rolled": {
      "D": "BTC1 A:00_0_D Curncy",
      "N": "BTC1 A:00_0_N Curncy",
      "R": "BTC1 A:00_0_R Curncy"
    },
    "sector": "Currency",
    "title": "CME Bitcoin",
    "v9": true
  },
  "CC": {
    "active": {
      "month_yr": "DEC 21",
      "rel": "CC_2021Z",
      "session": "2021-10-08",
      "symbol": "CCZ1"
    },
    "bloomberg": [
      "CC",
      "Comdty"
    ],
    "bloomberg_root": "CC",
    "common": "CC",
    "delta": {
      "root": "CC"
    },
    "exchange": "CC",
    "rolled": {
      "D": "CC1 A:00_0_D Comdty",
      "N": "CC1 A:00_0_N Comdty",
      "R": "CC1 A:00_0_R Comdty"
    },
    "sector": "Softs",
    "title": "Cocoa"
  },
  "CL": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "CL_2024Z",
      "session": "2024-11-11",
      "symbol": "CLZ4"
    },
    "bloomberg": [
      "CL",
      "Comdty"
    ],
    "bloomberg_root": "CL",
    "chain": {
      "live": false
    },
    "common": "CL",
    "deltas": "CL",
    "exchange": "CL",
    "rolled": {
      "D": "CL1 R:02_0_D Comdty",
      "N": "CL1 R:02_0_N Comdty",
      "R": "CL1 R:02_0_R Comdty"
    },
    "sector": "Energy",
    "title": "Crude Oil",
    "v9": true
  },
  "CNH": {
    "active": {
      "month_yr": "NOV 24",
      "rel": "CNH_2024X",
      "session": "2024-11-11",
      "symbol": "CNHX4"
    },
    "bloomberg": [
      "CHY",
      "Curncy"
    ],
    "bloomberg_root": "CHY",
    "common": "CNH",
    "exchange": "CNH",
    "rolled": {
      "D": "CHY1 A:00_0_D Curncy",
      "N": "CHY1 A:00_0_N Curncy",
      "R": "CHY1 A:00_0_R Curncy"
    },
    "sector": "Currency",
    "title": "Chinese Renminbi",
    "v9": true
  },
  "CT": {
    "active": {
      "month_yr": "DEC 21",
      "rel": "CT_2021Z",
      "session": "2021-10-08",
      "symbol": "CTZ1"
    },
    "bloomberg": [
      "CT",
      "Comdty"
    ],
    "bloomberg_root": "CT",
    "common": "CT",
    "delta": {
      "root": "CT"
    },
    "exchange": "CT",
    "rolled": {
      "D": "CT1 A:00_0_D Comdty",
      "N": "CT1 A:00_0_N Comdty",
      "R": "CT1 A:00_0_R Comdty"
    },
    "sector": "Softs",
    "title": "Cotton"
  },
  "DC": {
    "active": {
      "month_yr": "NOV 21",
      "rel": "DC_2021X",
      "session": "2021-10-08",
      "symbol": "DCX1"
    },
    "bloomberg": [
      "DA",
      "Comdty"
    ],
    "bloomberg_root": "DA",
    "chain": {
      "live": true
    },
    "common": "DA",
    "exchange": "DC",
    "rolled": {
      "D": "DA1 A:00_0_D Comdty",
      "N": "DA1 A:00_0_N Comdty",
      "R": "DA1 A:00_0_R Comdty"
    },
    "sector": "Misc",
    "title": "Class III Milk",
    "v9": true
  },
  "DX": {
    "active": {
      "month_yr": "DEC 21",
      "rel": "DX_2021Z",
      "session": "2021-10-08",
      "symbol": "DXZ1"
    },
    "bloomberg": [
      "DX",
      "Curncy"
    ],
    "bloomberg_root": "DX",
    "common": "DX",
    "exchange": "DX",
    "rolled": {
      "D": "DX1 A:00_0_D Curncy",
      "N": "DX1 A:00_0_N Curncy",
      "R": "DX1 A:00_0_R Curncy"
    },
    "sector": "Currency",
    "title": "US Dollar Index"
  },
  "ES": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "ES_2024Z",
      "session": "2024-11-11",
      "symbol": "ESZ4"
    },
    "bloomberg": [
      "ES",
      "Index"
    ],
    "bloomberg_root": "ES",
    "chain": {
      "live": false
    },
    "common": "ES",
    "deltas": "ES",
    "exchange": "ES",
    "rolled": {
      "D": "ES1 A:00_0_D Index",
      "N": "ES1 A:00_0_N Index",
      "R": "ES1 A:00_0_R Index"
    },
    "sector": "Financial",
    "title": "S&P 500 E-mini",
    "v9": true
  },
  "ETH": {
    "active": {
      "month_yr": "NOV 24",
      "rel": "ETH_2024X",
      "session": "2024-11-11",
      "symbol": "ETHX4"
    },
    "bloomberg": [
      "DCR",
      "Curncy"
    ],
    "bloomberg_root": "DCR",
    "common": "ETH",
    "exchange": "ETH",
    "rolled": {
      "D": "DCR1 A:00_0_D Curncy",
      "N": "DCR1 A:00_0_N Curncy",
      "R": "DCR1 A:00_0_R Curncy"
    },
    "sector": "Currency",
    "title": "CME Ether",
    "v9": true
  },
  "G": {
    "bloomberg": [
      "QS",
      "Comdty"
    ],
    "bloomberg_root": "QS",
    "common": "QS",
    "exchange": "G",
    "rolled": {
      "D": "QS1 R:02_0_D Comdty",
      "N": "QS1 R:02_0_N Comdty",
      "R": "QS1 R:02_0_R Comdty"
    },
    "sector": "Energy",
    "title": "Gasoil"
  },
  "GC": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "GC_2024Z",
      "session": "2024-11-11",
      "symbol": "GCZ4"
    },
    "bloomberg": [
      "GC",
      "Comdty"
    ],
    "bloomberg_root": "GC",
    "chain": {
      "live": false
    },
    "common": "GC",
    "deltas": "GC",
    "exchange": "GC",
    "rolled": {
      "D": "GC1 A:00_0_D Comdty",
      "N": "GC1 A:00_0_N Comdty",
      "R": "GC1 A:00_0_R Comdty"
    },
    "sector": "Metal/Wood",
    "title": "Gold",
    "v9": true
  },
  "GF": {
    "active": {
      "month_yr": "JAN 25",
      "rel": "GF_2025F",
      "session": "2024-11-11",
      "symbol": "GFF5"
    },
    "bloomberg": [
      "FC",
      "Comdty"
    ],
    "bloomberg_root": "FC",
    "chain": {
      "live": true
    },
    "common": "FC",
    "deltas": "FC",
    "exchange": "GF",
    "rolled": {
      "D": "FC1 A:00_0_D Comdty",
      "N": "FC1 A:00_0_N Comdty",
      "R": "FC1 A:00_0_R Comdty"
    },
    "sector": "Meat",
    "title": "Feeder Cattle",
    "v9": true
  },
  "HE": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "HE_2024Z",
      "session": "2024-11-11",
      "symbol": "HEZ4"
    },
    "bloomberg": [
      "LH",
      "Comdty"
    ],
    "bloomberg_root": "LH",
    "chain": {
      "live": true
    },
    "common": "LH",
    "deltas": "LH",
    "exchange": "HE",
    "rolled": {
      "D": "LH1 A:00_0_D Comdty",
      "N": "LH1 A:00_0_N Comdty",
      "R": "LH1 A:00_0_R Comdty"
    },
    "sector": "Meat",
    "title": "Lean Hogs",
    "v9": true
  },
  "HG": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "HG_2024Z",
      "session": "2024-11-11",
      "symbol": "HGZ4"
    },
    "bloomberg": [
      "HG",
      "Comdty"
    ],
    "bloomberg_root": "HG",
    "chain": {
      "live": false
    },
    "common": "HG",
    "deltas": "HG",
    "exchange": "HG",
    "rolled": {
      "D": "HG1 A:00_0_D Comdty",
      "N": "HG1 A:00_0_N Comdty",
      "R": "HG1 A:00_0_R Comdty"
    },
    "sector": "Metal/Wood",
    "title": "Copper",
    "v9": true
  },
  "HO": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "HO_2024Z",
      "session": "2024-11-11",
      "symbol": "HOZ4"
    },
    "bloomberg": [
      "HO",
      "Comdty"
    ],
    "bloomberg_root": "HO",
    "chain": {
      "live": false
    },
    "common": "HO",
    "deltas": "HO",
    "exchange": "HO",
    "rolled": {
      "D": "HO1 R:02_0_D Comdty",
      "N": "HO1 R:02_0_N Comdty",
      "R": "HO1 R:02_0_R Comdty"
    },
    "sector": "Energy",
    "title": "Heating Oil",
    "v9": true
  },
  "KC": {
    "active": {
      "month_yr": "DEC 21",
      "rel": "KC_2021Z",
      "session": "2021-10-08",
      "symbol": "KCZ1"
    },
    "bloomberg": [
      "KC",
      "Comdty"
    ],
    "bloomberg_root": "KC",
    "common": "KC",
    "delta": {
      "root": "KC"
    },
    "exchange": "KC",
    "rolled": {
      "D": "KC1 A:00_0_D Comdty",
      "N": "KC1 A:00_0_N Comdty",
      "R": "KC1 A:00_0_R Comdty"
    },
    "sector": "Softs",
    "title": "Coffee"
  },
  "KE": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "KE_2024Z",
      "session": "2024-11-11",
      "symbol": "KEZ4"
    },
    "bloomberg": [
      "KW",
      "Comdty"
    ],
    "bloomberg_root": "KW",
    "chain": {
      "live": true
    },
    "common": "KW",
    "deltas": "KE",
    "exchange": "KE",
    "rolled": {
      "D": "KW1 A:00_0_D Comdty",
      "N": "KW1 A:00_0_N Comdty",
      "R": "KW1 A:00_0_R Comdty"
    },
    "sector": "Grain/Oilseed",
    "title": "KC HRW Wheat",
    "v9": true
  },
  "LBR": {
    "active": {
      "month_yr": "JAN 25",
      "rel": "LBR_2025F",
      "session": "2024-11-11",
      "symbol": "LBRF5"
    },
    "bloomberg": [
      "LBO",
      "Comdty"
    ],
    "bloomberg_root": "LBO",
    "common": "LBO",
    "exchange": "LBR",
    "rolled": {
      "D": "LBO1 A:00_0_D Comdty",
      "N": "LBO1 A:00_0_N Comdty",
      "R": "LBO1 A:00_0_R Comdty"
    },
    "sector": "Metal/Wood",
    "title": "Lumber",
    "v9": true
  },
  "LE": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "LE_2024Z",
      "session": "2024-11-11",
      "symbol": "LEZ4"
    },
    "bloomberg": [
      "LC",
      "Comdty"
    ],
    "bloomberg_root": "LC",
    "chain": {
      "live": true
    },
    "common": "LC",
    "deltas": "LC",
    "exchange": "LE",
    "rolled": {
      "D": "LC1 A:00_0_D Comdty",
      "N": "LC1 A:00_0_N Comdty",
      "R": "LC1 A:00_0_R Comdty"
    },
    "sector": "Meat",
    "title": "Live Cattle",
    "v9": true
  },
  "MWE": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "MWE_2024Z",
      "session": "2024-11-11",
      "symbol": "MWEZ4"
    },
    "bloomberg": [
      "MW",
      "Comdty"
    ],
    "bloomberg_root": "MW",
    "chain": {
      "live": true
    },
    "common": "MW",
    "exchange": "MWE",
    "rolled": {
      "D": "MW1 A:00_0_D Comdty",
      "N": "MW1 A:00_0_N Comdty",
      "R": "MW1 A:00_0_R Comdty"
    },
    "sector": "Grain/Oilseed",
    "title": "MGEX HRS Wheat",
    "v9": true
  },
  "NG": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "NG_2024Z",
      "session": "2024-11-11",
      "symbol": "NGZ4"
    },
    "bloomberg": [
      "NG",
      "Comdty"
    ],
    "bloomberg_root": "NG",
    "chain": {
      "live": true
    },
    "common": "NG",
    "deltas": "NG",
    "exchange": "NG",
    "rolled": {
      "D": "NG1 R:02_0_D Comdty",
      "N": "NG1 R:02_0_N Comdty",
      "R": "NG1 R:02_0_R Comdty"
    },
    "sector": "Energy",
    "title": "Natural Gas",
    "v9": true
  },
  "NQ": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "NQ_2024Z",
      "session": "2024-11-11",
      "symbol": "NQZ4"
    },
    "bloomberg": [
      "NQ",
      "Index"
    ],
    "bloomberg_root": "NQ",
    "common": "NQ",
    "exchange": "NQ",
    "rolled": {
      "D": "NQ1 A:00_0_D Index",
      "N": "NQ1 A:00_0_N Index",
      "R": "NQ1 A:00_0_R Index"
    },
    "sector": "Financial",
    "title": "NASDAQ 100 E-mini",
    "v9": true
  },
  "PA": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "PA_2024Z",
      "session": "2024-11-11",
      "symbol": "PAZ4"
    },
    "bloomberg": [
      "PA",
      "Comdty"
    ],
    "bloomberg_root": "PA",
    "common": "PA",
    "exchange": "PA",
    "rolled": {
      "D": "PA1 A:00_0_D Comdty",
      "N": "PA1 A:00_0_N Comdty",
      "R": "PA1 A:00_0_R Comdty"
    },
    "sector": "Metal/Wood",
    "title": "Palladium",
    "v9": true
  },
  "PL": {
    "active": {
      "month_yr": "JAN 25",
      "rel": "PL_2025F",
      "session": "2024-11-11",
      "symbol": "PLF5"
    },
    "bloomberg": [
      "PL",
      "Comdty"
    ],
    "bloomberg_root": "PL",
    "chain": {
      "live": false
    },
    "common": "PL",
    "exchange": "PL",
    "rolled": {
      "D": "PL1 A:00_0_D Comdty",
      "N": "PL1 A:00_0_N Comdty",
      "R": "PL1 A:00_0_R Comdty"
    },
    "sector": "Metal/Wood",
    "title": "Platinum",
    "v9": true
  },
  "RB": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "RB_2024Z",
      "session": "2024-11-11",
      "symbol": "RBZ4"
    },
    "bloomberg": [
      "XB",
      "Comdty"
    ],
    "bloomberg_root": "XB",
    "chain": {
      "live": false
    },
    "common": "RB",
    "deltas": "RB",
    "exchange": "RB",
    "rolled": {
      "D": "XB1 R:02_0_D Comdty",
      "N": "XB1 R:02_0_N Comdty",
      "R": "XB1 R:02_0_R Comdty"
    },
    "sector": "Energy",
    "title": "RBOB Gasoline",
    "v9": true
  },
  "RTY": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "RTY_2024Z",
      "session": "2024-11-11",
      "symbol": "RTYZ4"
    },
    "bloomberg": [
      "RTY",
      "Index"
    ],
    "bloomberg_root": "RTY",
    "common": "RTY",
    "exchange": "RTY",
    "rolled": {
      "D": "RTY1 A:00_0_D Index",
      "N": "RTY1 A:00_0_N Index",
      "R": "RTY1 A:00_0_R Index"
    },
    "sector": "Financial",
    "title": "Russell 2000 E-mini",
    "v9": true
  },
  "SB": {
    "active": {
      "month_yr": "MAR 22",
      "rel": "SB_2022H",
      "session": "2021-10-08",
      "symbol": "SBH2"
    },
    "bloomberg": [
      "SB",
      "Comdty"
    ],
    "bloomberg_root": "SB",
    "common": "SB",
    "delta": {
      "root": "SB"
    },
    "exchange": "SB",
    "rolled": {
      "D": "SB1 A:00_0_D Comdty",
      "N": "SB1 A:00_0_N Comdty",
      "R": "SB1 A:00_0_R Comdty"
    },
    "sector": "Softs",
    "title": "Sugar"
  },
  "SI": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "SI_2024Z",
      "session": "2024-11-11",
      "symbol": "SIZ4"
    },
    "bloomberg": [
      "SI",
      "Comdty"
    ],
    "bloomberg_root": "SI",
    "chain": {
      "live": false
    },
    "common": "SI",
    "deltas": "SI",
    "exchange": "SI",
    "rolled": {
      "D": "SI1 A:00_0_D Comdty",
      "N": "SI1 A:00_0_N Comdty",
      "R": "SI1 A:00_0_R Comdty"
    },
    "sector": "Metal/Wood",
    "title": "Silver",
    "v9": true
  },
  "SR": {
    "bloomberg": [
      "SR",
      "Comdty"
    ],
    "bloomberg_root": "SR",
    "common": "SR",
    "exchange": "SR",
    "rolled": {
      "D": "SR1 R:12_0_D Comdty",
      "N": "SR1 R:12_0_N Comdty",
      "R": null
    },
    "sector": "Grain/Oilseed",
    "title": "Soy Crush"
  },
  "SR3": {
    "active": {
      "month_yr": "SEP 24",
      "rel": "SR3_2024U",
      "session": "2024-11-11",
      "symbol": "SR3U4"
    },
    "bloomberg": [
      "SFR",
      "Comdty"
    ],
    "bloomberg_root": "SFR",
    "chain": {
      "live": false
    },
    "common": "SFR",
    "deltas": "SR3",
    "exchange": "SR3",
    "rolled": {
      "D": "SFR1 A:00_0_D Comdty",
      "N": "SFR1 A:00_0_N Comdty",
      "R": "SFR1 A:00_0_R Comdty"
    },
    "sector": "Financial",
    "title": "3 Month SOFR",
    "v9": true
  },
  "UB": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "UB_2024Z",
      "session": "2024-11-11",
      "symbol": "UBZ4"
    },
    "bloomberg": [
      "WN",
      "Comdty"
    ],
    "bloomberg_root": "WN",
    "chain": {
      "live": true
    },
    "common": "UB",
    "exchange": "UB",
    "rolled": {
      "D": "WN1 A:00_0_D Comdty",
      "N": "WN1 A:00_0_N Comdty",
      "R": "WN1 A:00_0_R Comdty"
    },
    "sector": "Financial",
    "title": "Ultra Bond",
    "v9": true
  },
  "UX": {
    "bloomberg": [
      "UX",
      "Index"
    ],
    "bloomberg_root": "UX",
    "common": "UX",
    "exchange": "UX",
    "rolled": {
      "D": "UX1 A:00_0_D Index",
      "N": "UX1 A:00_0_N Index",
      "R": "UX1 A:00_0_R Index"
    },
    "sector": "Financial",
    "title": "CBOE VIX"
  },
  "YM": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "YM_2024Z",
      "session": "2024-11-11",
      "symbol": "YMZ4"
    },
    "bloomberg": [
      "DM",
      "Index"
    ],
    "bloomberg_root": "DM",
    "common": "DM",
    "exchange": "YM",
    "rolled": {
      "D": "DM1 A:00_0_D Index",
      "N": "DM1 A:00_0_N Index",
      "R": "DM1 A:00_0_R Index"
    },
    "sector": "Financial",
    "title": "Dow Jones E-mini",
    "v9": true
  },
  "ZB": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "ZB_2024Z",
      "session": "2024-11-11",
      "symbol": "ZBZ4"
    },
    "bloomberg": [
      "US",
      "Comdty"
    ],
    "bloomberg_root": "US",
    "chain": {
      "live": true
    },
    "common": "US",
    "deltas": "US",
    "exchange": "ZB",
    "rolled": {
      "D": "US1 A:00_0_D Comdty",
      "N": "US1 A:00_0_N Comdty",
      "R": "US1 A:00_0_R Comdty"
    },
    "sector": "Financial",
    "title": "T-Bond Futures",
    "v9": true
  },
  "ZC": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "ZC_2024Z",
      "session": "2024-11-11",
      "symbol": "ZCZ4"
    },
    "bloomberg": [
      "C ",
      "Comdty"
    ],
    "bloomberg_root": "C",
    "chain": {
      "live": true
    },
    "common": "C",
    "deltas": "C",
    "exchange": "ZC",
    "rolled": {
      "D": "C 1 A:00_0_D Comdty",
      "N": "C 1 A:00_0_N Comdty",
      "R": "C 1 A:00_0_R Comdty"
    },
    "sector": "Grain/Oilseed",
    "title": "Corn",
    "v9": true
  },
  "ZF": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "ZF_2024Z",
      "session": "2024-11-11",
      "symbol": "ZFZ4"
    },
    "bloomberg": [
      "FV",
      "Comdty"
    ],
    "bloomberg_root": "FV",
    "chain": {
      "live": true
    },
    "common": "FV",
    "deltas": "FV",
    "exchange": "ZF",
    "rolled": {
      "D": "FV1 A:00_0_D Comdty",
      "N": "FV1 A:00_0_N Comdty",
      "R": "FV1 A:00_0_R Comdty"
    },
    "sector": "Financial",
    "title": "5-Year T-Note",
    "v9": true
  },
  "ZL": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "ZL_2024Z",
      "session": "2024-11-11",
      "symbol": "ZLZ4"
    },
    "bloomberg": [
      "BO",
      "Comdty"
    ],
    "bloomberg_root": "BO",
    "chain": {
      "live": true
    },
    "common": "BO",
    "deltas": "BO",
    "exchange": "ZL",
    "rolled": {
      "D": "BO1 A:00_0_D Comdty",
      "N": "BO1 A:00_0_N Comdty",
      "R": "BO1 A:00_0_R Comdty"
    },
    "sector": "Grain/Oilseed",
    "title": "Soybean Oil",
    "v9": true
  },
  "ZM": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "ZM_2024Z",
      "session": "2024-11-11",
      "symbol": "ZMZ4"
    },
    "bloomberg": [
      "SM",
      "Comdty"
    ],
    "bloomberg_root": "SM",
    "chain": {
      "live": true
    },
    "common": "SM",
    "deltas": "SM",
    "exchange": "ZM",
    "rolled": {
      "D": "SM1 A:00_0_D Comdty",
      "N": "SM1 A:00_0_N Comdty",
      "R": "SM1 A:00_0_R Comdty"
    },
    "sector": "Grain/Oilseed",
    "title": "Soybean Meal",
    "v9": true
  },
  "ZN": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "ZN_2024Z",
      "session": "2024-11-11",
      "symbol": "ZNZ4"
    },
    "bloomberg": [
      "TY",
      "Comdty"
    ],
    "bloomberg_root": "TY",
    "chain": {
      "live": true
    },
    "common": "TY",
    "deltas": "TY",
    "exchange": "ZN",
    "rolled": {
      "D": "TY1 A:00_0_D Comdty",
      "N": "TY1 A:00_0_N Comdty",
      "R": "TY1 A:00_0_R Comdty"
    },
    "sector": "Financial",
    "title": "10-Year T-Note",
    "v9": true
  },
  "ZO": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "ZO_2024Z",
      "session": "2024-11-11",
      "symbol": "ZOZ4"
    },
    "bloomberg": [
      "O ",
      "Comdty"
    ],
    "bloomberg_root": "O",
    "chain": {
      "live": true
    },
    "common": "O",
    "exchange": "ZO",
    "rolled": {
      "D": "O 1 A:00_0_D Comdty",
      "N": "O 1 A:00_0_N Comdty",
      "R": "O 1 A:00_0_R Comdty"
    },
    "sector": "Grain/Oilseed",
    "title": "Oats",
    "v9": true
  },
  "ZQ": {
    "active": {
      "month_yr": "NOV 24",
      "rel": "ZQ_2024X",
      "session": "2024-11-11",
      "symbol": "ZQX4"
    },
    "bloomberg": [
      "FF",
      "Comdty"
    ],
    "bloomberg_root": "FF",
    "common": "FF",
    "exchange": "ZQ",
    "rolled": {
      "D": "FF1 A:00_0_D Comdty",
      "N": "FF1 A:00_0_N Comdty",
      "R": "FF1 A:00_0_R Comdty"
    },
    "sector": "Financial",
    "title": "Fed Funds",
    "v9": true
  },
  "ZR": {
    "active": {
      "month_yr": "JAN 25",
      "rel": "ZR_2025F",
      "session": "2024-11-11",
      "symbol": "ZRF5"
    },
    "bloomberg": [
      "RR",
      "Comdty"
    ],
    "bloomberg_root": "RR",
    "chain": {
      "live": true
    },
    "common": "RR",
    "exchange": "ZR",
    "rolled": {
      "D": "RR1 A:00_0_D Comdty",
      "N": "RR1 A:00_0_N Comdty",
      "R": "RR1 A:00_0_R Comdty"
    },
    "sector": "Grain/Oilseed",
    "title": "Rough Rice",
    "v9": true
  },
  "ZS": {
    "active": {
      "month_yr": "JAN 25",
      "rel": "ZS_2025F",
      "session": "2024-11-11",
      "symbol": "ZSF5"
    },
    "bloomberg": [
      "S ",
      "Comdty"
    ],
    "bloomberg_root": "S",
    "chain": {
      "live": true
    },
    "common": "S",
    "deltas": "S",
    "exchange": "ZS",
    "rolled": {
      "D": "S 1 A:00_0_D Comdty",
      "N": "S 1 A:00_0_N Comdty",
      "R": "S 1 A:00_0_R Comdty"
    },
    "sector": "Grain/Oilseed",
    "title": "Soybean",
    "v9": true
  },
  "ZT": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "ZT_2024Z",
      "session": "2024-11-11",
      "symbol": "ZTZ4"
    },
    "bloomberg": [
      "TU",
      "Comdty"
    ],
    "bloomberg_root": "TU",
    "chain": {
      "live": true
    },
    "common": "TU",
    "deltas": "TU",
    "exchange": "ZT",
    "rolled": {
      "D": "TU1 A:00_0_D Comdty",
      "N": "TU1 A:00_0_N Comdty",
      "R": "TU1 A:00_0_R Comdty"
    },
    "sector": "Financial",
    "title": "2-Year T-Note",
    "v9": true
  },
  "ZW": {
    "active": {
      "month_yr": "DEC 24",
      "rel": "ZW_2024Z",
      "session": "2024-11-11",
      "symbol": "ZWZ4"
    },
    "bloomberg": [
      "W ",
      "Comdty"
    ],
    "bloomberg_root": "W",
    "chain": {
      "live": true
    },
    "common": "W",
    "deltas": "W",
    "exchange": "ZW",
    "rolled": {
      "D": "W 1 A:00_0_D Comdty",
      "N": "W 1 A:00_0_N Comdty",
      "R": "W 1 A:00_0_R Comdty"
    },
    "sector": "Grain/Oilseed",
    "title": "Wheat",
    "v9": true
  }
};

export const _sector_lookup = {
  "Currency": [
    "6B",
    "6E",
    "6J",
    "6S",
    "6M",
    "6L",
    "6A",
    "6C",
    "CNH",
    "BTC",
    "ETH",
    "DX"
  ],
  "Energy": [
    "BRN",
    "G",
    "CL",
    "RB",
    "HO",
    "NG"
  ],
  "Financial": [
    "ES",
    "NQ",
    "RTY",
    "YM",
    "UX",
    "ZQ",
    "SR3",
    "ZT",
    "ZF",
    "ZN",
    "ZB",
    "UB"
  ],
  "Grain/Oilseed": [
    "ZC",
    "ZS",
    "ZL",
    "ZM",
    "SR",
    "ZW",
    "KE",
    "MWE",
    "ZO",
    "ZR"
  ],
  "Meat": [
    "HE",
    "LE",
    "GF"
  ],
  "Metal/Wood": [
    "GC",
    "SI",
    "PL",
    "PA",
    "HG",
    "LBR"
  ],
  "Softs": [
    "KC",
    "SB",
    "CC",
    "CT"
  ]
};
 

export function by_sector (sector=null) {
    if (!sector)
        return Object.keys(_sector_lookup).sort();
    return(_sector_lookup[sector]);
    
}

export function all_sectors () {
    return(by_sector())
}

export function all_by_sector (sector) {
    const keys = new Set(by_sector(sector))
    return Object.keys(_exchange_symbol_lookup).filter(key=>keys.has(key)).map(key=>_exchange_symbol_lookup[key])
}

export function by_exchange (symbol, exchange='cme') {
    if (symbol in _exchange_symbol_lookup) {
	return(_exchange_symbol_lookup[symbol]);
    }
}

export function valid_exchange () {
    return(Object.keys(_exchange_symbol_lookup).sort());
}

//console.log(by_exchange('BRN'));

const _common_symbol_lookup = Object.values(_exchange_symbol_lookup).reduce(
    function (hash, entry) {
	if ('common' in entry) {
	    hash[entry.common] = entry;
	}
	return(hash);
    }, {}
);

export function by_common (symbol) {
    if (symbol in _common_symbol_lookup) {
	return(_common_symbol_lookup[symbol]);
    }
}


export function valid_common () {
    return(Object.keys(_common_symbol_lookup).sort());
    // (a,b) => { _common_symbol_lookup[a].sector == _common_symbol_lookup[b].sector ? 0 : ( _common_symbol_lookup[a].sector > _common_symbol_lookup[b].sector ? 1 : -1 ) }
}

//console.log(by_common('CO'));
//console.log(valid_common());

const _deltas_symbol_lookup = Object.values(_exchange_symbol_lookup).reduce(
    function (hash, entry) {
	if ('deltas' in entry) {
	    hash[entry.deltas] = entry;
	}
	return(hash);
    }, {}
);

export function by_deltas (symbol) {
    if (symbol in _deltas_symbol_lookup) {
	return(_deltas_symbol_lookup[symbol]);
    }
}

//console.log(by_deltas('BRN'));

const _delta_symbol_lookup = Object.values(_exchange_symbol_lookup).reduce(
    function (hash, entry) {
	if ('delta' in entry) {
	    hash[entry.delta.root] = entry;
	}
	return(hash);
    }, {}
);

export function by_delta (symbol) {
    if (symbol in _delta_symbol_lookup) {
	return(_delta_symbol_lookup[symbol]);
    }
}

//console.log(by_delta('BRN'));

const _overload_symbol_lookup = Object.values(_exchange_symbol_lookup).reduce(
    function (hash, entry) {
	if ('common' in entry) {
	    hash[entry.common] = entry;
	}
	if ('exchange' in entry) {
	    // if (entry.exchange != entry.common) {
	    if (entry.exchange !== entry.common) {
		if (entry.exchange in hash) {
		    console.log('conflict', entry.exchange, entry.common);
		} else {
		    hash[entry.exchange] = entry;
		}
	    }
	}
	return(hash);
    }, {}
);

export function by_overload (symbol) {
    if (symbol in _overload_symbol_lookup) {
	return(_overload_symbol_lookup[symbol]);
    }
}

//console.log(by_overload('CO'));

//console.log(Object.keys(_overload_symbol_lookup));

